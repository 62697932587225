import { AppName } from './types';

export default {
  app: {
    favicon: `${process.env.PUBLIC_URL}/favicons/${AppName.MAT_MAN_STUDENT}.ico`
  },
  header: {
    title: 'A–Z for Mat Man® and Me',
    backgroundColor: 'rgb(160,216,237)',
    textColor: '#000',
    appNameTitle: `${AppName.MAT_MAN_STUDENT}`,
    displayAccessibilitySettings: false
  },
  body: {
    backgroundColor: 'rgb(189,210,172)'
  },
  buttons: {
    go: {
      backgroundColor: '#005BBB',
      textColor: '#FFF'
    }
  },
  links: {
    about: {
      href: 'https://www.lwtears.com/AZSAPP',
      textColor: '#0057A3'
    },
    support: {
      textColor: '#0057A3'
    }
  }
};
