import React from 'react';
import styles from './DragDrop.module.scss';
import PropTypes from 'prop-types';
import Draggable from './Draggable';
import { Target } from './Target';

const DragDrop = (props) => {
  return <div className={styles.DragDrop}>{props.children}</div>;
};

DragDrop.propTypes = { children: PropTypes.node.isRequired };

DragDrop.Draggable = Draggable;
DragDrop.Target = Target;

export default DragDrop;
