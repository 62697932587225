import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import modalClose from 'assets/svg/modal-close.svg';
import Header from './Header';
import style from '../style/PinRecovery.module.scss';
import common from '../style/common.module.scss';
import Background from 'components/common/Theme/Background';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const t = (key) => translate('common', key);

const { REACT_APP_SUPPORT_EMAIL } = process.env;

const MyPin = () => {
  const closeBtn = useRef();
  const email = useRef();
  const getFocusedElement = (ev, element) => {
    ev.stopPropagation();
    ev.preventDefault();
    element.focus();
  };
  useEffect(() => {
    document.body.classList.contains('using-keyboard') &&
      document.getElementById('closeBtn').focus();
  }, []);
  return (
    <div className={common.selectPage}>
      <div className={style.overlay} />
      <Header logoOnly />
      <Background className={style.container}>
        <div className={style.box}>
          <Link
            to="/login"
            className={style.closeIcon}
            id="closeBtn"
            ref={closeBtn}
            onKeyDown={(ev) =>
              ev.shiftKey && ev.key === 'Tab' && getFocusedElement(ev, email.current)
            }
          >
            <img src={modalClose} alt={t('CloseWhatsMyPinModalAltText')} />
          </Link>
          <div className={style.modal}>
            <h1 className={style.pin}>{t(`What's my PIN?`)}</h1>
            <p className={style.desc}>{t(`teacherPinLocation`)}</p>
            <a
              href="https://pli.lwtears.com/"
              rel="noopener noreferrer"
              target="_blank"
              className={style.btnPlusLive}
            >
              {t('Take me to +Live Insights')}
            </a>
            <div className={style.contactDetails}>
              <p className={style.bold}>{t(`Still can't locate your PIN?`)}</p>
              <p className={style.contactNumber}>{t('contact')}</p>
              <a
                className={style.contactLink}
                href={`mailto:${REACT_APP_SUPPORT_EMAIL}`}
                ref={email}
                onKeyDown={(ev) => ev.key === 'Tab' && getFocusedElement(ev, closeBtn.current)}
              >
                {t('Email Us')}
              </a>
            </div>
          </div>
        </div>
      </Background>
    </div>
  );
};

export default MyPin;
