import axios from 'axios';
import { UNAUTHORIZED, FORBIDDEN, BAD_GATEWAY, GATEWAY_TIMEOUT } from 'http-status';
import store from 'state';

const {
  REACT_APP_GATEKEEPER_API,
  REACT_APP_USERNAME,
  REACT_APP_AUTHTOKEN,
  REACT_APP_APP_VERSION,
  REACT_APP_BUILD_NUMBER
} = process.env;

const defaultHeaders = {
  'x-lwtears-app-version': REACT_APP_APP_VERSION,
  'x-lwtears-app-build': REACT_APP_BUILD_NUMBER
};

const api = axios.create({
  baseURL: REACT_APP_GATEKEEPER_API,
  headers: defaultHeaders
});

api.interceptors.request.use(
  (config) => {
    const {
      login: { app, sessionId }
    } = store.getState();

    if (app) config.headers['x-lwtears-app'] = app;

    if (sessionId) {
      config.headers['x-lwtears-session'] = sessionId;
    } else {
      config.headers['x-ssousername'] = REACT_APP_USERNAME;
      config.headers['x-authtoken'] = REACT_APP_AUTHTOKEN;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const createLogEvent = async (type, data) => {
  const timestamp = new Date().toISOString();

  try {
    await api.post('/logs', { type, timestamp, data });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }
};

const execute = async (method, url, options = {}, retry = false) => {
  try {
    const { status, data } = await api({ method, url, ...options });

    return {
      status,
      data
    };
  } catch (error) {
    const { status, data } = error?.response;
    if (status !== UNAUTHORIZED && status !== FORBIDDEN)
      createLogEvent('api-error', { method, url, ...options, status, data });

    if (retry || (status !== BAD_GATEWAY && status !== GATEWAY_TIMEOUT)) return { status, error };

    return execute(method, url, options, true);
  }
};

export const put = (url, options) => execute('PUT', url, options);

export const get = (url, options) => execute('GET', url, options);

export const post = (url, options) => execute('POST', url, options);
