import uuidv4 from 'uuid/v4';
import {
  SET_CLASSROOMS,
  SELECT_CLASS,
  SELECT_USER,
  SET_SESSION_ID,
  SET_APP,
  SET_REDIRECT,
  LOGIN_STEP,
  LOGIN_USER,
  GO_TO_STEP,
  LOGIN_EDUCATOR_PIN,
  LOGIN_PIN_ERROR,
  LOGIN_USER_ERROR,
  LOGIN_USER_ERROR_TYPE
} from 'state/login/types';
import getAppConfig from 'app-config';

const initLogin = {
  users: [],
  app: '',
  classroom: {},
  classrooms: [],
  hasNewClassrooms: true,
  error: null,
  loggedIn: false,
  selectedUserId: null,
  sessionId: '',
  step: LOGIN_STEP.SHOW_LOGIN,
  redirectURL: ''
};

export const loginReducer = (state = initLogin, action) => {
  switch (action.type) {
    case SET_APP:
      return {
        ...state,
        app: action.app,
        config: getAppConfig(action.app)
      };
    case SET_REDIRECT:
      return {
        ...state,
        redirectURL: action.url
      };
    case SET_CLASSROOMS:
      return {
        ...state,
        loggedIn: true,
        step: LOGIN_STEP.SHOW_CLASSES,
        classrooms: action.classrooms,
        hasNewClassrooms: action.hasNewClassrooms
      };
    case LOGIN_EDUCATOR_PIN:
      return {
        ...state,
        error: null,
        loggedIn: true,
        step: LOGIN_STEP.SHOW_CLASSES,
        classrooms: action.payload
      };
    case GO_TO_STEP:
      return {
        ...state,
        loggedIn: action.step > 0,
        error: null,
        step: action.step
      };
    case SELECT_CLASS:
      return {
        ...state,
        step: LOGIN_STEP.SHOW_USERS,
        classroom: action.classroom,
        users: state.classrooms
          .find(({ id }) => id === action.classroom.id)
          .students.sort((a, b) => a.username - b.username)
      };
    case SELECT_USER:
      return { ...state, selectedUserId: action.selectedUserId, step: LOGIN_STEP.SHOW_PIN };
    case SET_SESSION_ID:
      return { ...state, sessionId: action.sessionId };
    case LOGIN_USER:
      return {
        ...state,
        loggedIn: true
      };
    case LOGIN_PIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        // Invalid PIN. Please try again
        error: { message: 'InvalidPin' }
      };
    case LOGIN_USER_ERROR:
      // Oops. Try again.
      return {
        ...state,
        error: { id: uuidv4(), message: LOGIN_USER_ERROR_TYPE[action.errorType] ?? 'OopsTryAgain' }
      };

    default:
      return state;
  }
};
