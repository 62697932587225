import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_LOGIN_SOUNDS,
  SET_COMMON_SOUNDS,
  CLEAR_LOGIN_SOUNDS,
  TOGGLE_MODAL,
  SET_CURRENT_CC
} from './types';

export const initialState = {
  loading: false,
  sounds: {
    common: {}
  },
  isModalOpen: false,
  currentCaption: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SPINNER:
      return { ...state, loading: true };
    case HIDE_SPINNER:
      return { ...state, loading: false };
    case SET_LOGIN_SOUNDS:
      return {
        ...state,
        sounds: {
          ...state.sounds,
          login: { ...action.payload }
        }
      };
    case CLEAR_LOGIN_SOUNDS:
      return {
        ...state,
        sounds: {
          ...state.sounds,
          login: {}
        }
      };
    case SET_COMMON_SOUNDS:
      return {
        ...state,
        sounds: {
          ...state.sounds,
          common: { ...state.sounds.common, ...action.payload }
        }
      };
    case TOGGLE_MODAL:
      return { ...state, isModalOpen: action.isModalOpen };
    case SET_CURRENT_CC:
      return { ...state, currentCaption: action.payload };
    default:
      return state;
  }
};
