import { AppName } from './types';

export default {
  app: {
    favicon: `${process.env.PUBLIC_URL}/favicons/wdt.ico`,
    title: 'Wet Dry Try',
    description: 'WDT description'
  },
  header: {
    title: '',
    image: `${process.env.PUBLIC_URL}/img/header-wdt.png`,
    backgroundColor: '#fff6ab',
    textColor: '#FFF',
    appNameTitle: `${AppName.WDT}`,
    displayAccessibilitySettings: true
  },
  body: {
    backgroundColor: '#4a7ace'
  },
  buttons: {
    go: {
      backgroundColor: '#005f8d',
      textColor: '#FFF'
    }
  },
  links: {
    about: {
      textColor: '#0057A3'
    },
    support: {
      textColor: '#0057A3'
    }
  }
};
