import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import DragDrop from 'components/common/Draggable/DragDrop';
import { LOGIN_STEP } from 'state/login/types';
import Header from './Header';
import common from '../style/common.module.scss';
import style from '../style/StudentPin.module.scss';
import commonAssets from 'utils/commonAssets';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const { images } = commonAssets;
const t = (key) => translate('common', key);

const numbers = [
  { id: '1', card: images.letterCard1 },
  { id: '2', card: images.letterCard2 },
  { id: '3', card: images.letterCard3 },
  { id: '4', card: images.letterCard4 },
  { id: '5', card: images.letterCard5 }
];

const letters = [
  { id: 'A', card: images.letterCardA },
  { id: 'B', card: images.letterCardB },
  { id: 'C', card: images.letterCardC },
  { id: 'D', card: images.letterCardD },
  { id: 'E', card: images.letterCardE }
];

class StudentPin extends React.Component {
  constructor(props) {
    super(props);

    const { users, selectedUserId } = props;
    const { login, username } = users.find((user) => user.id === selectedUserId);

    this.state = {
      pin: '',
      activePieces: [],
      username,
      login,
      whiteBoxFocus: false
    };

    this.onDragSuccess = this.onDragSuccess.bind(this);
    this.pinRefComponent = new Array(() => createRef());
  }

  componentDidUpdate(prevProps) {
    const prevError = prevProps.error || {};
    const currentError = this.props.error || {};
    if (prevError.id !== currentError.id) {
      this.pinRefComponent = new Array(() => createRef());
      this.setState({ pin: '' });
    }
  }

  checkPinMatch() {
    const { pin, login } = this.state;
    const { selectedClassId, selectedUserId, loginUser } = this.props;

    if (pin.length === login.length) {
      setTimeout(() => loginUser(selectedUserId, pin, selectedClassId), 600);
    }
  }

  onDragSuccess({ id }) {
    const pinChar = id.slice(0, -1);
    if (!this.state.activePieces.includes(id)) {
      this.setState(
        ({ pin, activePieces }) => ({
          pin: `${pin}${pinChar}`,
          activePieces: [...activePieces, id],
          whiteBoxFocus: true
        }),
        this.checkPinMatch
      );
    }
  }

  onPinReset = (x, y, id) => {
    if (this.state.activePieces.includes(id)) {
      this.setState(({ pin }) => ({
        activePieces: [...this.state.activePieces.filter((item) => item !== id)],
        pin: pin.slice(0, -1)
      }));
    }
  };

  onPinCleanUp = () => {
    const items = this.pinRefComponent.filter(
      (item) =>
        item.key >= 0 && (item.instance.state.deltaX !== 0 || item.instance.state.deltaY !== 0)
    );
    items.forEach((item) => {
      item.instance.state.deltaX = 0;
      item.instance.state.deltaY = 0;
    });
    this.setState({ pin: '', activePieces: [], whiteBoxFocus: false });
  };

  onPasswordKeyUp = (e, id) => {
    switch (e.key) {
      case 'Tab': {
        this.setState({ whiteBoxFocus: true });
        break;
      }
      case 'Enter': {
        const item = this.pinRefComponent.filter((item) => item.id == id && !item.used)[0];
        if (item != null) {
          item.instance.handleTap();
          item.used = true;
        }
        break;
      }
    }
  };

  createPinReference = (instance, id, key) => {
    const pinRef = this.pinRefComponent;
    if (instance != null && pinRef.filter((item) => item.id == id).length < 6) {
      pinRef.push({ id, key, instance, used: false });
    }
  };

  focusOnDestinationBox = (index) => {
    const classList = document.body.classList;
    const { pin, whiteBoxFocus } = this.state;
    return whiteBoxFocus && index == pin.length && classList.contains('using-keyboard')
      ? style.boxFocus
      : style.box;
  };

  onFocusOut = () => {
    this.setState({ whiteBoxFocus: false });
  };

  onPinCardClick = () => {
    this.setState({ whiteBoxFocus: false });
  };

  render() {
    const { pin, username, login, activePieces } = this.state;
    const { goToStep, error, contentScaling } = this.props;

    const dragTargets = new Array(login.length).fill(0).map((_, index) => ({
      correct: true,
      content: (
        <div>
          <div className={this.focusOnDestinationBox(index)} id={`secret${index}`}>
            <img
              className={[style.img, style[`img${contentScaling}`]].join(' ')}
              src={letters[0].card.src}
              alt={t('SecretCodeBox')}
            />
          </div>
        </div>
      ),
      ref: React.createRef()
    }));

    const boxes = dragTargets.map((dragTarget, id) => (
      <DragDrop.Target forwardedRef={dragTarget.ref} key={id}>
        {dragTarget.content}
      </DragDrop.Target>
    ));

    const upperCaseFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);

    const repeat = new Array(6).fill(0);
    const pinCard = (divId, id, card, index) => (
      <button
        className={['login-button', style.dragGroup, style[`dragGroup${contentScaling}`]].join(' ')}
        id={`${divId}-${index + 1}`}
        aria-label={`${upperCaseFirstLetter(divId)} ${id} ${t('card')}`}
        key={id}
        onKeyUp={(e) => this.onPasswordKeyUp(e, id, card)}
        onBlur={(e) => this.onFocusOut(e)}
        onMouseDown={() => this.onPinCardClick()}
      >
        {repeat.map((_r, key) => (
          <DragDrop.Draggable
            ref={(instance) => {
              this.createPinReference(instance, id, key);
            }}
            allowTap={activePieces.length < login.length && !activePieces.includes(`${id}${key}`)}
            allowDrag={
              activePieces.length < login.length &&
              !(
                activePieces.includes(`${id}${key}`) &&
                activePieces[activePieces.length - 1] !== `${id}${key}`
              )
            }
            id={`${id}${key}`}
            key={key}
            classList={{
              dragged: style.dragged,
              initial: style.draggable
            }}
            targets={
              activePieces.includes(`${id}${key}`)
                ? dragTargets.slice(pin.length - 1, pin.length)
                : dragTargets.slice(pin.length, pin.length + 1)
            }
            callback={this.onDragSuccess}
            onTap={this.onDragSuccess}
            onBadDrag={this.onPinReset}
            soundFx={{ sndCorrect: true, sndWrong: true }}
          >
            <img
              className={[style.card, style[`card${contentScaling}`]].join(' ')}
              src={card.src}
              alt={`${upperCaseFirstLetter(divId)} ${id} ${t('card')}`}
            />
          </DragDrop.Draggable>
        ))}
      </button>
    );

    const lettersList = letters.map(({ id, card }, index) => pinCard('letter', id, card, index));
    const numbersList = numbers.map(({ id, card }, index) => pinCard('number', id, card, index));

    return (
      <div className={common.selectPage}>
        <Header message={username} previousStep={LOGIN_STEP.SHOW_USERS} goToStep={goToStep} />
        <div className={[common.content, common[`content${contentScaling}`]].join(' ')}>
          <h1 className={[common.heading, common[`heading${contentScaling}`]].join(' ')}>
            {`${username}, ${t('EnterYourSecretCode')} `}
          </h1>
          <div className={style.container} key={(error || {}).id}>
            <div className={style.boxesRow}>{boxes}</div>
            {error && <p className={style.error}>{t(error.message)}</p>}
            <div className={style.clearPassword}>
              <button
                className={['login-button', style.button, style[`button${contentScaling}`]].join(
                  ' '
                )}
                onClick={this.onPinCleanUp}
              >
                {t('ClearSecretCode')}
              </button>
            </div>
            <div
              className={[style.numbersRow, style[`numbersRow${contentScaling}`]].join(' ')}
              id="letter-list"
            >
              {lettersList}
            </div>
            <div className={[style.lettersRow, style[`lettersRow${contentScaling}`]].join(' ')}>
              {numbersList}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StudentPin.propTypes = {
  users: PropTypes.array,
  selectedClassId: PropTypes.number,
  selectedUserId: PropTypes.number,
  error: PropTypes.object,
  loginUser: PropTypes.func,
  goToStep: PropTypes.func,
  contentScaling: PropTypes.number
};

export default StudentPin;
