import React, { Component } from 'react';
import { connect } from 'react-redux';
import Hammer from 'hammerjs';
import { isIOS, isMobileSafari } from 'react-device-detect';
import styles from './DragDrop.module.scss';
import PropTypes from 'prop-types';

const MOUSE = 'mouse';

const initialState = {
  deltaX: 0,
  deltaY: 0,
  lastX: 0,
  lastY: 0,
  collision: {
    id: undefined,
    haveCollision: false,
    isCorrect: false
  },
  isDragging: false
};
export const TRAILS_LENGTH = 15;

export class Draggable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
      ...initialState
    };
    this.intervals = [];
    this.timers = [];
    this.container = React.createRef();
  }

  componentDidMount() {
    const { allowDrag = true, allowTap = true } = this.props;
    this.hammer = new Hammer(this.container.current);
    this.hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });
    if (allowDrag || allowTap) {
      allowDrag && this.hammer.on('pan touch', this.handleDrag);
      allowTap && this.hammer.on('tap', (e) => this.handleTap(e));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.togglePosition !== this.props.togglePosition) this.togglePosition();
    if (prevProps.allowDrag !== this.props.allowDrag) {
      if (this.props.allowDrag) {
        this.hammer.on('pan touch', this.handleDrag);
      } else {
        this.hammer.off('pan touch', this.handleDrag);
      }
    }
    if (prevProps.allowTap !== this.props.allowTap) {
      if (this.props.allowTap) {
        this.hammer.on('tap', (e) => this.handleTap(e));
      } else {
        this.hammer.off('tap', (e) => this.handleTap(e));
      }
    }
  }

  componentWillUnmount() {
    this.hammer && this.hammer.destroy();
    this.timers.forEach((timer) => {
      clearTimeout(timer);
    });
  }

  playSound = (sound) => {
    const { soundFx = { sndWrong: true, sndCorrect: true, sndMoving: true } } = this.props;
    if (typeof soundFx !== 'boolean' && !soundFx[sound]) return;
    this.props[sound] && this.props[sound].play();
  };

  startTrail = () => {
    const {
      props: { animationTime = 500 }
    } = this;
    const frequency = (animationTime / 500) * 4;
    const makeTrailTimer = setInterval(this.makeTrail, frequency);
    this.intervals.push(makeTrailTimer);
  };

  makeTrail = () => {
    const trails = document.getElementsByClassName(styles.trail);
    if (trails.length >= TRAILS_LENGTH) document.getElementById('root').removeChild(trails[0]);
    const coords = this.container.current.getBoundingClientRect();
    const trail = document.createElement('div');
    trail.className = styles.trail;
    trail.style.left = `${coords.left}px`;
    trail.style.top = `${coords.top}px`;
    document.getElementById('root').appendChild(trail);
  };

  clearTrail = () => {
    this.intervals.forEach((timer) => {
      clearTimeout(timer);
    });
    const trails = document.getElementsByClassName(styles.trail);
    while (trails[0]) {
      trails[0].parentNode.removeChild(trails[0]);
    }
  };

  handleTap = (e) => {
    // Negate (cordova iPad / iPad safari browser) app tap event handler from being called twice
    if (isIOS && isMobileSafari && e && e.pointerType === MOUSE) {
      return;
    }

    const { targets, onTap, id, allowTap = true, trail = false } = this.props;
    if (allowTap) {
      trail && this.startTrail();
      if (targets[0].isCorrect !== false) {
        this.playSound('sndMoving');
        this.snapTo(targets[0].ref.current);
      } else this.playSound('sndWrong');
      onTap && onTap({ id });
    }
  };

  togglePosition = () => {
    const { togglePosition = false } = this.props;
    togglePosition && this.handleTap();
    !togglePosition && this.resetPosition();
  };

  handleDrag = (ev) => {
    if (!this.state.animate) {
      if (!this.state.isDragging) {
        this.props.trail && this.startTrail();
        this.setState({
          isDragging: true,
          lastX: this.state.deltaX,
          lastY: this.state.deltaY
        });
        this.playSound('sndMoving');
      }
      this.setState({
        deltaX: ev.deltaX + this.state.lastX,
        deltaY: ev.deltaY + this.state.lastY
      });

      if (ev.isFinal) {
        this.setState({ isDragging: false });
        this.props.trail && this.clearTrail();
        !this.props.targetWrapper && this.collisionCheck();
        this.props.targetWrapper && this.collisionCheckWrapper();
      }
    }
  };

  collisionCheckWrapper() {
    const coords = this.container.current.children[0].getBoundingClientRect();
    const thisCoords = this.props.targetWrapper.current.getBoundingClientRect();
    const targets = this.props.targets;
    const collision =
      coords.left < thisCoords.left + thisCoords.width &&
      coords.left + coords.width > thisCoords.left &&
      coords.top < thisCoords.top + thisCoords.height &&
      coords.top + coords.height > thisCoords.top;
    if (collision) {
      if (targets[0].isCorrect !== false) {
        this.snapTo(targets[0].ref.current);
      } else {
        this.resetPosition(true);
      }
    } else {
      /* Send back x and y data if requested */
      if (this.props.onBadDrag) {
        const x = Math.round(coords.left);
        const y = Math.round(coords.top);
        this.props.onBadDrag(x, y);
      }
      this.resetPosition();
    }
  }

  collisionCheck() {
    const coords = this.container.current.children[0].getBoundingClientRect();
    const targets = this.props.targets.filter((target) => {
      const thisCoords = target.ref.current.getBoundingClientRect();
      return (
        coords.left < thisCoords.left + thisCoords.width &&
        coords.left + coords.width > thisCoords.left &&
        coords.top < thisCoords.top + thisCoords.height &&
        coords.top + coords.height > thisCoords.top
      );
    });

    if (targets.length) {
      let target = targets[0];

      if (1 < targets.length) {
        target = this.closestCollision(targets);
      }

      this.snapTo(target.ref.current);

      const collision = {
        id: this.props.id,
        isCorrect: target.correct,
        haveCollision: true,
        target,
        instance: this
      };

      if (target.id) collision.targetId = target.id;

      this.setState({ collision });
      this.props.callback && this.props.callback(collision);

      if (!target.correct) {
        const timer = setTimeout(() => this.resetPosition(), this.props.resetTime || 500);
        this.timers.push(timer);
      }
    } else {
      /* Send back x and y data if requested */
      if (this.props.onBadDrag) {
        const x = Math.round(coords.left);
        const y = Math.round(coords.top);
        const id = this.props.id;
        this.props.onBadDrag(x, y, id);
      }

      this.resetPosition();
    }
  }

  closestCollision(targets) {
    const [selfX, selfY] = this.getCenter(this.container.current);
    let distance = 10000;
    let closest = targets[0];
    targets.forEach((val) => {
      const [targetX, targetY] = this.getCenter(val.ref.current);
      const tempDistance = Math.sqrt(Math.pow(targetX - selfX, 2) + Math.pow(targetY - selfY, 2));
      if (tempDistance < distance) {
        distance = tempDistance;
        closest = val;
      }
    });
    return closest;
  }

  getCenter(target) {
    const coords = target.getBoundingClientRect();
    return [coords.left + coords.width / 2, coords.top + coords.height / 2];
  }

  snapTo(target) {
    const [targetX, targetY] = this.getCenter(target);
    const [selfX, selfY] = this.getCenter(this.container.current);
    const adjustX = selfX - targetX;
    const adjustY = selfY - targetY;

    this.setState({
      animate: true,
      deltaX: this.state.deltaX - adjustX,
      deltaY: this.state.deltaY - adjustY
    });

    const timer = setTimeout(() => {
      this.props.onSnap && this.props.onSnap();
      this.playSound('sndCorrect');
      this.setState({ animate: false });
      this.props.trail && this.clearTrail();
    }, this.props.animationTime || 500);
    this.timers.push(timer);
  }

  resetPosition(sendFalse = false) {
    this.setState({ animate: true, ...initialState });
    sendFalse && this.playSound('sndWrong');
    const timer = setTimeout(() => {
      this.setState({ animate: false });
      sendFalse && this.props.onSnap && this.props.onSnap(false);
    }, this.props.animationTime || 500);
    this.timers.push(timer);
  }

  getHtmlClasses() {
    const classList = this.props.classList || {};
    const {
      state: {
        animate,
        deltaX,
        deltaY,
        collision: { haveCollision, isCorrect },
        isDragging
      },
      props: { animationTime }
    } = this;

    const classes = [styles.draggableItem];
    classes.push(classList.initial);

    if (haveCollision && isCorrect) {
      classes.push(styles.collided);
      classes.push(classList.dragged);
    }

    if (haveCollision && !isCorrect) {
      classes.push(styles.collidedWrong);
    }

    if (isDragging) {
      classes.push(styles.Dragging);
    }

    let inlineStyles = { transform: `translate(${deltaX}px, ${deltaY}px)` };
    if (animate && animationTime > 0) {
      inlineStyles.transition = `transform ${animationTime || 500}ms ease-in-out`;
      classes.push(styles.animate);
    }

    return {
      classes: classes.filter((c) => c),
      inlineStyles
    };
  }

  render() {
    const { classes, inlineStyles } = this.getHtmlClasses();

    if (this.props.scaling) {
      const distance = (Math.abs(this.state.deltaX) + Math.abs(this.state.deltaY)) / 1500;
      const scale = distance <= 0.6 ? 1 - distance : 0.4;

      return (
        <div
          id={this.props.divId}
          className={classes.join(' ')}
          ref={this.container}
          style={inlineStyles}
        >
          <div
            className={styles.scaleDown}
            style={{
              transform: `scale(${scale})`
            }}
          >
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div
          id={this.props.divId}
          className={classes.join(' ')}
          ref={this.container}
          style={inlineStyles}
        >
          {this.props.children}
        </div>
      );
    }
  }
}

Draggable.propTypes = {
  allowDrag: PropTypes.bool, //Whether to register pan events, defaults to true
  allowTap: PropTypes.bool, //Whether to register tap events, defaults to true
  animationTime: PropTypes.number, //How long the animation for snapping/resetting should be - defaults to 500
  callback: PropTypes.func, //Function to be called when there is a collision - to be used for match it & trash it
  children: PropTypes.node,
  classList: PropTypes.object, //Class list to inject
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // id to be passed to onTap function
  onBadDrag: PropTypes.func, //If the drag is considered "wrong" this will pass you back the x,y coordinates ( for error logger )
  onTap: PropTypes.func, //Function to be called the moment you click a draggable
  onSnap: PropTypes.func, //Function to be called after the draggable has finished snapping to a target (depends on animationTime)
  resetTime: PropTypes.number, // Delay before snapping back to position on wrong collision - defaults to 500
  scaling: PropTypes.bool, //Whether to shrink when dragged, for Match It Trash It
  sndCorrect: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]), // sound to play on correct collision
  sndMoving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]), // swooshing sound to play when moving squares
  sndWrong: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]), // sound to play on false collision
  soundFx: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]), // list of sounds enabled or false
  targets: PropTypes.array.isRequired, //Array of targets / for taping it will snap to first position in array
  targetWrapper: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]), //This should be used to check collision against a container of targets
  togglePosition: PropTypes.bool, //Toggling this on/off will send the draggable to the first target in targets array
  trail: PropTypes.bool, //Whether to show trailing animation; defaults to false
  divId: PropTypes.string
};

const mapStateToProps = (state) => ({
  sndCorrect: state.ui.sounds.common.sndCorrect || { play: () => undefined },
  sndMoving: state.ui.sounds.common.sndMoving || { play: () => undefined },
  sndWrong: state.ui.sounds.common.sndWrong || { play: () => undefined }
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(Draggable);
