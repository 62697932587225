// /* eslint-disable */
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './AudioCaptions.module.scss';
import { useLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';

const AudioCaptions = () => {
  const [{ settings }] = useLwtStore();
  const { currentCaption } = useSelector((state) => state.ui);
  const {
    closedCaptionSize,
    showClosedCaptions,
    displayCCControl
  } = settings.accessibility.closedCaptionsSettings;

  return (
    <div className={styles.captionsContainer}>
      {(displayCCControl || showClosedCaptions) && (
        <div className={`${styles.captions}`}>
          <p className={styles.captionTrack} style={{ fontSize: closedCaptionSize }}>
            {currentCaption}
          </p>
        </div>
      )}
    </div>
  );
};

export default AudioCaptions;
