import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { requestClassrooms } from 'state/login/actions';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import storage from 'store';
import HowlerWithCCs from 'utils/captionsHelper';

import { LOGIN_STEP as STEP } from 'state/login/types';
import { goToStep, selectClass, postSinglePin, loginUser, selectUser } from 'state/login/actions';
import { loadCommonAssets, unloadLoginSounds } from 'state/ui/actions';

import AudioCaptions from 'components/common/AudioCaptions';
import LoginEducatorPin from './EducatorPin';
import LoginClasses from './ClassList';
import LoginUsers from './UserList';
import LoginPin from './StudentPin';
import StudentPinPassword from './StudentPinPassword';
import { getLocale, setLocale } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

import styles from '../style/Login.module.scss';
import Background from 'components/common/Theme/Background';
import { withLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';

const { SHOW_LOGIN, SHOW_CLASSES, SHOW_USERS, SHOW_PIN } = STEP;
class Login extends React.Component {
  async componentDidMount() {
    const { loadCommonAssets } = this.props;

    const pins = storage.get('pins') || [];
    if (pins.length) {
      await this.props.requestClassrooms(pins);
    }

    await loadCommonAssets();
  }

  async componentDidUpdate(prevProps) {
    const {
      props: { classrooms, selectedClassId, loading, step, history, loginSounds }
    } = this;

    const { sounds, captions } = loginSounds ? loginSounds : { sounds: null, captions: null };

    let usedLocale = getLocale();
    if (step < prevProps.step && step <= STEP.SHOW_CLASSES) {
      await setLocale('en-US');
      usedLocale = 'en-US';
    }

    if (
      loading ||
      (step === prevProps.step && prevProps.loading === loading) ||
      !sounds ||
      !sounds[usedLocale]
    )
      return;

    if (step === STEP.SHOW_USERS) {
      const classroom = classrooms.find((c) => c.id === selectedClassId);
      if (classroom && classroom.locale) {
        usedLocale = classroom.locale;
      } else {
        // play en-US if no locale
        usedLocale = 'en-US';
      }

      await setLocale(usedLocale);
    }

    Object.keys(sounds).forEach((key) =>
      Object.keys(sounds[key]).forEach((subKey) => {
        return sounds[key][subKey].stop();
      })
    );

    const subscribeAndPlay = (sound, captions) => {
      const {
        settings: {
          accessibility: { audioSettings }
        }
      } = this.props.lwtStoreState;

      HowlerWithCCs(sound, captions);
      audioSettings.muteSounds ? sound.volume(0) : sound.volume(audioSettings.volume / 100);
      sound.play();
    };

    switch (step) {
      case SHOW_LOGIN:
        subscribeAndPlay(
          sounds[usedLocale]['enterYourTeachersPin'],
          captions[usedLocale]['enterYourTeachersPin']
        );
        break;
      case SHOW_CLASSES:
        if (classrooms.length > 0) {
          subscribeAndPlay(
            sounds[usedLocale]['chooseYourClass'],
            captions[usedLocale]['chooseYourClass']
          );
        }
        break;
      case SHOW_USERS:
        subscribeAndPlay(
          sounds[usedLocale]['chooseYourName'],
          captions[usedLocale]['chooseYourName']
        );
        break;
      case SHOW_PIN:
        subscribeAndPlay(
          sounds[usedLocale]['enterYourSecretCode'],
          captions[usedLocale]['enterYourSecretCode']
        );
        break;
      default:
        return;
    }
  }

  activeScreen = () => {
    const {
      loginUser,
      selectedClassId,
      selectedUserId,
      error,
      selectUser,
      goToStep,
      users,
      classrooms,
      hasNewClassrooms,
      selectClass,
      step,
      loggedIn,
      loading,
      postSinglePin
    } = this.props;

    const studentPinComponent = () => {
      const { login } = users.find((user) => user.id === selectedUserId);
      const props = {
        loginUser,
        goToStep,
        selectedClassId,
        selectedUserId,
        users,
        error,
        contentScaling
      };
      const mappedComponent =
        login.type === 'LETTER_CARDS' ? <LoginPin {...props} /> : <StudentPinPassword {...props} />;
      return mappedComponent;
    };

    const {
      settings: {
        accessibility: {
          displaySettings: { contentScaling }
        }
      }
    } = this.props.lwtStoreState;

    switch (step) {
      case SHOW_CLASSES:
        return (
          <LoginClasses
            {...{ classrooms, goToStep, selectClass, hasNewClassrooms, contentScaling }}
          />
        );
      case SHOW_USERS:
        return <LoginUsers {...{ selectUser, goToStep, users, contentScaling }} />;
      case SHOW_PIN:
        return studentPinComponent();
      case SHOW_LOGIN:
      default:
        return (
          <LoginEducatorPin {...{ error, loggedIn, loading, postSinglePin, contentScaling }} />
        );
    }
  };

  componentWillUnmount() {
    // unload sounds used for login
    this.props.unloadLoginSounds();
  }

  render() {
    const {
      props: { loading, step }
    } = this;

    if (loading) {
      return (
        <Background className={styles.loginComponent}>
          <div className={[styles.loading, 'loader'].join(' ')}></div>
        </Background>
      );
    }

    return (
      <Background className={styles.loginComponent}>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={step}
            timeout={200}
            classNames={{
              enter: 'fade-enter',
              enterActive: 'fade-enter-active',
              exit: 'fade-exit'
            }}
          >
            {this.activeScreen()}
          </CSSTransition>
        </SwitchTransition>
        <AudioCaptions />
      </Background>
    );
  }
}

Login.propTypes = {
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  step: PropTypes.number,
  selectedUserId: PropTypes.number,
  error: PropTypes.object,
  history: PropTypes.object,
  users: PropTypes.array,
  classrooms: PropTypes.array,
  hasNewClassrooms: PropTypes.bool,
  loginUser: PropTypes.func.isRequired,
  selectUser: PropTypes.func.isRequired,
  selectClass: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  requestClassrooms: PropTypes.func.isRequired,
  postSinglePin: PropTypes.func.isRequired,
  loadCommonAssets: PropTypes.func.isRequired,
  selectedClassId: PropTypes.number,
  commonSounds: PropTypes.object,
  loginSounds: PropTypes.object,
  unloadLoginSounds: PropTypes.func
};

const mapStateToProps = (state) => ({
  step: state.login.step,
  classrooms: state.login.classrooms,
  hasNewClassrooms: state.login.hasNewClassrooms,
  loading: state.ui.loading,
  error: state.login.error,
  loggedIn: state.login.loggedIn,
  users: state.login.users,
  selectedUserId: state.login.selectedUserId,
  commonSounds: state.ui.sounds.common,
  loginSounds: state.ui.sounds.login,
  selectedClassId: state.login.classroom.id
});

const mapDispatchToProps = {
  goToStep,
  selectClass,
  postSinglePin,
  requestClassrooms,
  loginUser,
  selectUser,
  loadCommonAssets,
  unloadLoginSounds
};

export default connect(mapStateToProps, mapDispatchToProps)(withLwtStore(withRouter(Login)));
