import commonAssets from 'utils/commonAssets';
import AssetLoader from 'utils/assetLoader';

const queueAudio = (assetLoader, assets) =>
  Object.values(assets).forEach(({ src, captions }) => {
    assetLoader.queueAudio(src);
    if (captions) assetLoader.queueCaptions(captions);
  });

// const queueImages = (assetLoader, assets) =>
//   Object.values(assets).forEach(({ src }) => assetLoader.queueImage(src));

const buildLocalizedCache = (cache, obj) =>
  Object.entries(obj).reduce(
    (acc, [key, { src, captions }]) => {
      acc.sounds[key] = cache.get(src);
      acc.captions[key] = cache.get(captions);
      return acc;
    },
    { sounds: {}, captions: {} }
  );

export const loadLocalizedAudio = async () => {
  const {
    audio: { sfx, ...audio }
  } = commonAssets;

  const assetLoader = new AssetLoader();
  assetLoader.flushHowler();

  Object.values(audio).forEach((assets) => queueAudio(assetLoader, assets));

  const cache = await assetLoader.downloadAll();

  return Object.entries(audio).reduce(
    (acc, [locale, assets]) => {
      const { sounds, captions } = buildLocalizedCache(cache, assets);
      acc.sounds[locale] = sounds;
      acc.captions[locale] = captions;
      return acc;
    },
    { sounds: {}, captions: {} }
  );
};

const buildCache = (cache, obj) =>
  Object.entries(obj).reduce((acc, [key, { src }]) => {
    acc[key] = cache.get(src);
    return acc;
  }, {});

export const loadSoundEffects = async () => {
  const {
    audio: { sfx }
  } = commonAssets;

  const assetLoader = new AssetLoader();
  queueAudio(assetLoader, sfx);

  const cache = await assetLoader.downloadAll();

  return {
    audio: buildCache(cache, sfx)
  };
};
