import {
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_LOGIN_SOUNDS,
  SET_COMMON_SOUNDS,
  CLEAR_LOGIN_SOUNDS,
  TOGGLE_MODAL,
  SET_CURRENT_CC
} from './types';

import { loadLocalizedAudio, loadSoundEffects } from 'utils/loadAssets';

export const showSpinner = () => ({ type: SHOW_SPINNER });
export const hideSpinner = () => ({ type: HIDE_SPINNER });
export const setCurrentCaption = (caption) => ({ type: SET_CURRENT_CC, payload: caption });

export const setLoginSounds = (sounds) => ({ type: SET_LOGIN_SOUNDS, payload: sounds });
export const setCommonSounds = (sounds) => ({ type: SET_COMMON_SOUNDS, payload: sounds });

export const clearLoginSounds = () => ({ type: CLEAR_LOGIN_SOUNDS });

export const loadCommonAssets = () => async (dispatch) => {
  dispatch(showSpinner());

  const loginSounds = await loadLocalizedAudio();
  const { audio: sounds } = await loadSoundEffects();

  dispatch(setLoginSounds(loginSounds));
  dispatch(setCommonSounds(sounds));
  dispatch(hideSpinner());
};

export const unloadLoginSounds = () => (dispatch, getState) => {
  const {
    ui: {
      sounds: { login }
    }
  } = getState();

  /* eslint-disable no-unused-vars */
  for (const language in login) {
    for (const sound in login[language]) {
      const currSnd = login[language][sound];
      'unload' in currSnd && currSnd.unload();
    }
  }
  /* eslint-enble no-unused-vars */

  dispatch(clearLoginSounds());
};

export const toggleModal = (isModalOpen) => ({ type: TOGGLE_MODAL, isModalOpen });
