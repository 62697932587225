import { AppName } from './types';

export default {
  app: {
    favicon: `${process.env.PUBLIC_URL}/favicons/${AppName.GSS}.ico`
  },
  header: {
    title: '',
    image: `${process.env.PUBLIC_URL}/img/header-${AppName.GSS}.svg`,
    backgroundColor: '#0077be',
    textColor: '#FFF',
    borderImage: `${process.env.PUBLIC_URL}/img/black-line-${AppName.GSS}.svg`,
    arrowBackImage: `${process.env.PUBLIC_URL}/img/arrow-back-${AppName.GSS}.svg`,
    appNameTitle: `${AppName.GSS}`,
    displayAccessibilitySettings: true
  },
  body: {
    backgroundColor: '#E9F6CC'
  },
  buttons: {
    go: {
      backgroundColor: '#005BBB',
      textColor: '#FFF'
    }
  },
  links: {
    about: {
      textColor: '#0057A3'
    },
    support: {
      textColor: '#0057A3'
    }
  }
};
