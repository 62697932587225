import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../style/EducatorPin.module.scss';
import Header from './Header';
import Background from 'components/common/Theme/Background';
import { useSelector } from 'react-redux';
import getAppConfig from 'app-config';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const t = (key) => translate('common', key);
const tApp = (app, key) => translate(app, key);

export const LoginButton = ({ loading, disabled, contentScaling }) => {
  const goButton = useSelector((state) => getAppConfig(state.login.app).buttons.go);

  return (
    <button
      type="submit"
      className={['login-button', styles.boxGo, styles[`boxGo${contentScaling}`]].join(' ')}
      style={{ backgroundColor: goButton.backgroundColor, color: goButton.textColor }}
      id="go-submit-button"
      disabled={disabled}
    >
      {loading && <div className="loader loader-button"></div>}
      {!loading && t('Go')}
    </button>
  );
};

export const LoginError = ({ error, loggedIn }) => {
  if (!error || loggedIn) return null;
  return <p className={styles.pinError}>{t(error.message)}</p>;
};

export const LoginBody = ({ error, loggedIn, loading, postSinglePin, contentScaling }) => {
  const [input, setInput] = useState('');
  const onChange = (e) => setInput(e.target.value);
  const onSubmit = (e) => {
    e.preventDefault();
    const pin = input.trim().toUpperCase();
    postSinglePin(pin);
  };
  return (
    <div className="login-body">
      <div className={[styles.loginBox, styles[`loginBox${contentScaling}`]].join(' ')}>
        <form
          className={[styles.boxContainer, styles[`boxContainer${contentScaling}`]].join(' ')}
          onSubmit={onSubmit}
        >
          <h1 className={[styles.boxHeader, styles[`boxHeader${contentScaling}`]].join(' ')}>
            {t('StudentLogin')}
          </h1>
          <label
            className={[styles.boxMessage, styles[`boxMessage${contentScaling}`]].join(' ')}
            htmlFor="educator-pin"
          >
            {t('EnterEducatorPin')}
          </label>
          <input
            type="text"
            value={input}
            placeholder={t('EnterEducatorPin')}
            onChange={onChange}
            className={[styles.boxInput, styles[`boxInput${contentScaling}`]].join(' ')}
            id="educator-pin"
          />
          <LoginError loggedIn={loggedIn} error={error} />
          <LoginButton loading={loading} disabled={!input} contentScaling={contentScaling} />
          <Link
            to="/pin-recovery"
            className={[styles.boxPin, styles[`boxPin${contentScaling}`]].join(' ')}
          >
            {t('WhatsMyPin')}
          </Link>
        </form>
      </div>
    </div>
  );
};

export const LoginFooter = ({ contentScaling }) => {
  const { app } = useSelector((state) => state.login);
  const config = getAppConfig(app);

  return (
    <div className={styles.loginFooter}>
      <a
        className={[styles.footerText, styles[`footerText${contentScaling}`]].join(' ')}
        rel="noopener noreferrer"
        href={config.links.about.href || 'https://www.lwtears.com/hwt'}
        target="_blank"
        style={{ color: config.links.support.textColor }}
      >
        {tApp(app, 'about')}
      </a>
      <a
        className={[styles.footerText, styles[`footerText${contentScaling}`]].join(' ')}
        rel="noopener noreferrer"
        href="https://pli.lwtears.com/support"
        target="_blank"
        style={{ color: config.links.support.textColor }}
      >
        {t('Support')}
      </a>
    </div>
  );
};

const LoginEducatorPin = ({ error, loggedIn, loading, postSinglePin, contentScaling }) => {
  return (
    <div className={styles.logoContainer}>
      <Header logoOnly />
      <Background
        className={[styles.loginContent, styles[`loginContent${contentScaling}`]].join(' ')}
      >
        <LoginBody
          loading={loading}
          loggedIn={loggedIn}
          postSinglePin={postSinglePin}
          error={error}
          contentScaling={contentScaling}
        />
        <LoginFooter contentScaling={contentScaling} />
      </Background>
    </div>
  );
};

LoginBody.propTypes = {
  error: PropTypes.object,
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  postSinglePin: PropTypes.func
};

LoginError.propTypes = {
  error: PropTypes.object,
  loggedIn: PropTypes.bool
};

LoginButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool
};

LoginEducatorPin.propTypes = {
  error: PropTypes.object,
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  postSinglePin: PropTypes.func,
  contentScaling: PropTypes.number
};

export default LoginEducatorPin;
