import React from 'react';
import styles from './DragDrop.module.scss';
import PropTypes from 'prop-types';

export const Target = (props) => {
  let inlineStyle = {};
  if (props.outline) {
    inlineStyle = {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: props.outline
    };
  }
  return (
    <div id={props.divId} ref={props.forwardedRef} className={styles.target} style={inlineStyle}>
      {props.children}
    </div>
  );
};

Target.propTypes = {
  children: PropTypes.node,
  forwardedRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  outline: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  divId: PropTypes.string
};
