import React from 'react';
import { LOGIN_STEP as LS } from 'state/login/types';
import PropTypes from 'prop-types';
import common from '../style/common.module.scss';
import Header from './Header';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const t = (key) => translate('common', key);

const ClassList = ({ classrooms, goToStep, selectClass, hasNewClassrooms, contentScaling }) => {
  const headerText = () => {
    return classrooms.length === 0
      ? 'NoClassesAvailable'
      : hasNewClassrooms === false
      ? 'NoNewClassesAvailable'
      : 'ChooseYourClass';
  };

  return (
    <div className={common.selectPage}>
      <Header message={t('class')} previousStep={LS.SHOW_LOGIN} goToStep={goToStep} />
      <div className={[common.content, common[`content${contentScaling}`]].join(' ')}>
        <h1 className={[common.heading, common[`heading${contentScaling}`]].join(' ')}>
          {t(headerText())}
        </h1>
        <div className={common.list} id="choose-class">
          {classrooms.map((cls, index) => (
            <button
              key={cls.id}
              className={[
                'login-button',
                common.listItem,
                common[`listItem${contentScaling}`]
              ].join(' ')}
              onClick={async () => await selectClass(cls)}
            >
              <p id={`class-${index + 1}`}>{cls.name}</p>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

ClassList.propTypes = {
  classrooms: PropTypes.array.isRequired,
  hasNewClassrooms: PropTypes.bool,
  goToStep: PropTypes.func.isRequired,
  selectClass: PropTypes.func.isRequired,
  contentScaling: PropTypes.number
};

export default ClassList;
