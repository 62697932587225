import React, { useEffect, useState, useContext, createContext } from 'react';
import commonAssets from 'utils/commonAssets';

const { images } = commonAssets;

const preloadImage = (url) => {
  const img = new Image();
  img.src = url;

  return img;
};

const AssetCacheContext = createContext<any>([]);

// TODO: with a little effort this could use a web worker and be moved to lwt-common-frontend
export const AssetCacheProvider: React.FC = (props) => {
  const [cachedImages, setCachedImages] = useState([]);

  // run once: preload images
  useEffect(() => {
    const values = Object.keys(images).map((key) => {
      return preloadImage(images[key].src) as any;
    });

    setCachedImages(values as any);
  }, []);

  return (
    <AssetCacheContext.Provider value={{ cachedImages }}>
      {props.children}
    </AssetCacheContext.Provider>
  );
};

export const useAssetCache = () => {
  const [state, dispatch] = useContext(AssetCacheContext);

  return [state, dispatch];
};
