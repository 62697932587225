import React from 'react';
import { LOGIN_STEP as LS } from 'state/login/types';
import PropTypes from 'prop-types';
import Header from './Header';
import common from '../style/common.module.scss';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const t = (key) => translate('common', key);

const LoginUsers = ({ selectUser, goToStep, users, contentScaling }) => {
  const usersList = users.map((user, id) => {
    return (
      <button
        key={id}
        className={['login-button', common.listItem, common[`listItem${contentScaling}`]].join(' ')}
        onClick={async () => await selectUser(user.id)}
      >
        <p id={`student-${id + 1}`}>{user.username}</p>
      </button>
    );
  });

  return (
    <div className={common.selectPage}>
      <Header message={t('name')} goToStep={goToStep} previousStep={LS.SHOW_CLASSES} />
      <div className={[common.content, common[`content${contentScaling}`]].join(' ')}>
        <h1 className={[common.heading, common[`heading${contentScaling}`]].join(' ')}>
          {t('ChooseYourUser')}
        </h1>
        <div className={common.list} id="user-list">
          {usersList}
        </div>
      </div>
    </div>
  );
};

LoginUsers.propTypes = {
  selectUser: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  contentScaling: PropTypes.number
};

export default LoginUsers;
