import axios from 'axios';
import { Howl, Howler } from 'howler';
import { isIOS } from 'react-device-detect';

const ERR_HOWL = (src) => ({
  /* eslint-disable no-console */
  play: () => console.warn(`There was an error loading the sound ${src}, unable to play it.`),
  stop: () => console.warn(`There was an error loading the sound ${src}, unable to stop it.`)
  /* eslint-enable no-console */
});

const _isValidAudioSrc = (src) => src.endsWith('.mp3') || src.includes('.mp3?');
export default class AssetLoader {
  constructor() {
    this.cache = new Map();
    this.queue = { images: [], sounds: [], vtts: [], captions: [] };
  }
  _notInCache = (filename) => !this.cache.has(filename);

  _addToQueue = (asset, queueName) => {
    if (!asset || !queueName) return;

    this.queue[queueName] = [...new Set([...this.queue[queueName], asset])];
  };

  _addToCache = (src, asset) => this.cache.set(src, asset);

  _loadImage = (src) =>
    new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve(this._addToCache(src, image));
      image.onerror = () => resolve(this._addToCache(src, image));
      image.src = src;
    });

  _loadVtt = async (vtt) => {
    await axios
      .get(vtt)
      .then((response) => this._addToCache(vtt, response.data))
      .catch((error) => {
        console.log(error);
      });
  };

  _loadCaptions = async (captions) => {
    await axios
      .get(captions)
      .then((response) => this._addToCache(captions, response.data))
      .catch((error) => {
        console.log(error);
      });
  };

  _loadAudio = (options) => (src = '') =>
    new Promise((resolve) => {
      if (typeof src !== 'string' || !_isValidAudioSrc(src)) {
        return resolve(this._addToCache(src, ERR_HOWL(src)));
      }

      const audio = new Howl({
        src,
        ...options,
        onload: () => resolve(this._addToCache(src, audio)),
        onloaderror: (e, msg) => {
          console.log(msg); //eslint-disable-line no-console
          resolve(this._addToCache(src, ERR_HOWL(src)));
        }
      });
    });

  _downloadAssets = async (queue, loader) => {
    await Promise.all(queue.map(loader));

    // reset queue
    this.queue = { images: [], sounds: [], captions: [], vtts: [] };

    return this.cache;
  };

  _downloadImages = () => this._downloadAssets(this.queue.images, this._loadImage);
  _downloadVtts = () => this._downloadAssets(this.queue.vtts, this._loadVtt);
  _downloadCaptions = () => this._downloadAssets(this.queue.captions, this._loadCaptions);

  _downloadAudio = () => {
    const audioLoader = this._loadAudio({
      preload: true,
      html5: isIOS ? true : false
    });

    return this._downloadAssets(this.queue.sounds, audioLoader);
  };

  queueImage = (assetPath) => this._addToQueue(assetPath, 'images');

  queueAudio = (assetPath) => this._addToQueue(assetPath, 'sounds');

  queueVtts = (assetPath) => this._addToQueue(assetPath, 'vtts');

  queueCaptions = (assetPath) => this._addToQueue(assetPath, 'captions');

  async downloadAll() {
    await Promise.all([
      this._downloadImages(this.queue.images),
      this._downloadAudio(this.queue.sounds),
      this._downloadVtts(this.queue.vtts),
      this._downloadCaptions(this.queue.captions)
    ]);
    return Promise.resolve(this.cache);
  }

  flushHowler = () => {
    Howler.stop();
    Howler.unload();
  };
}
