export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_EDUCATOR_PIN = 'LOGIN_EDUCATOR_PIN';
export const LOGIN_USER = 'LOGIN_USER';
export const GO_TO_STEP = 'GO_TO_STEP';
export const SELECT_CLASS = 'SELECT_CLASS';
export const SELECT_USER = 'SELECT_USER';
export const SET_CLASSROOMS = 'SET_CLASSROOMS';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_APP = 'SET_APP';
export const SET_REDIRECT = 'SET_REDIRECT';

// error handling
export const LOGIN_PIN_ERROR = 'LOGIN_PIN_ERROR';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_USER_ERROR_TYPE = {
  STUDENT_NO_LICENSE: 'STUDENT_NO_LICENSE'
};

export const LOGIN_STEP = {
  SHOW_LOGIN: 0,
  SHOW_CLASSES: 1,
  SHOW_USERS: 2,
  SHOW_PIN: 3
};
