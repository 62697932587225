import { AppName } from './types';

export default {
  app: {
    favicon: `${process.env.PUBLIC_URL}/favicons/${AppName.HWT_STUDENT}.ico`
  },
  header: {
    title: 'Handwriting Without Tears®',
    image: '',
    backgroundColor: '#fff6ab',
    textColor: '#000000',
    appNameTitle: `${AppName.HWT_STUDENT}`,
    displayAccessibilitySettings: false
  },
  body: {
    backgroundColor: '#9ec2f4'
  },
  buttons: {
    go: {
      backgroundColor: '#005f8d',
      textColor: '#FFF'
    }
  },
  links: {
    about: {
      textColor: '#0057A3'
    },
    support: {
      textColor: '#0057A3'
    }
  }
};
