import React from 'react';
import PropTypes from 'prop-types';
import { LOGIN_STEP as LS } from 'state/login/types';
import Header from './Header';
import common from '../style/common.module.scss';
import style from '../style/StudentPinPassword.module.scss';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const t = (key) => translate('common', key);

class StudentPinPassword extends React.Component {
  constructor(props) {
    super(props);

    const { users, selectedUserId } = props;
    const { login, username } = users.find((user) => user.id === selectedUserId);
    this.passwordInputRef = React.createRef();

    this.state = {
      password: '',
      username,
      login
    };
  }
  componentDidMount() {
    this.passwordInputRef.current.focus();
  }

  componentDidUpdate(prevProps) {
    const prevError = prevProps.error || {};
    const currentError = this.props.error || {};
    if (prevError.id !== currentError.id) {
      this.setState({ password: '' });
    }
  }

  loginStudent = () => {
    this.props.loginUser(
      this.props.selectedUserId,
      this.state.password,
      this.props.selectedClassId
    );
  };

  updatePassword = (e) => this.setState({ password: e.target.value.toUpperCase() });

  handleEnter = (e) => {
    // eslint-disable-next-line
    if (e.charCode == '13') {
      this.props.loginUser(
        this.props.selectedUserId,
        this.state.password,
        this.props.selectedClassId
      );
      e.preventDefault();
    }
  };
  render() {
    const { goToStep, error, contentScaling } = this.props;
    const { password } = this.state;

    return (
      <div className={common.selectPage}>
        <Header message={this.state.username} previousStep={LS.SHOW_USERS} goToStep={goToStep} />
        <div
          className={[
            common.content,
            common[`content${contentScaling}`],
            common.contentCenter
          ].join(' ')}
        >
          <h1 className={[common.heading, common[`heading${contentScaling}`]].join(' ')}>
            {`${this.state.username}, ${t('EnterYourSecretCode')}`}
          </h1>
          <div className={[style.formContainer, style[`formContainer${contentScaling}`]].join(' ')}>
            <input
              placeholder={t('EnterYourSecretCode')}
              value={this.state.password}
              aria-describedby={t('EnterYourSecretCode')}
              onChange={this.updatePassword}
              onKeyPress={this.handleEnter}
              className={[style.password, style[`password${contentScaling}`]].join(' ')}
              ref={this.passwordInputRef}
              id="secret-code-text-box"
            />
            {error && (
              <p className={[style.error, style[`error${contentScaling}`]].join(' ')}>
                {t(error.message)}
              </p>
            )}
            <button
              id="text-box-go-button"
              className={['login-button', style.button, style[`button${contentScaling}`]].join(' ')}
              onClick={this.loginStudent}
              disabled={!password}
            >
              {t('Go')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

StudentPinPassword.propTypes = {
  users: PropTypes.array,
  selectedClassId: PropTypes.number,
  selectedUserId: PropTypes.number,
  error: PropTypes.object,
  loginUser: PropTypes.func,
  goToStep: PropTypes.func,
  contentScaling: PropTypes.number
};

export default StudentPinPassword;
