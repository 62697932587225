const { REACT_APP_S3 } = process.env;

const getAudioPath = (filepath, locale) =>
  locale
    ? `${REACT_APP_S3}/audio/${locale.toLowerCase()}${filepath}`
    : `${REACT_APP_S3}/audio${filepath}`;
const getImagePath = (filepath) => `${REACT_APP_S3}/images${filepath}`;

export default {
  audio: {
    'en-US': {
      chooseYourClass: {
        src: getAudioPath('/choose-your-class.mp3', 'en-US'),
        captions: getAudioPath('/choose-your-class.json', 'en-US')
      },
      chooseYourName: {
        src: getAudioPath('/choose-your-name.mp3', 'en-US'),
        captions: getAudioPath('/choose-your-name.json', 'en-US')
      },
      enterYourSecretCode: {
        src: getAudioPath('/enter-your-secret-code.mp3', 'en-US'),
        captions: getAudioPath('/enter-your-secret-code.json', 'en-US')
      },
      enterYourTeachersPin: {
        src: getAudioPath('/enter-your-teachers-pin.mp3', 'en-US'),
        captions: getAudioPath('/enter-your-teachers-pin.json', 'en-US')
      }
    },
    'es-US': {
      chooseYourClass: {
        src: getAudioPath('/escoge-tu-clase.mp3', 'es-US'),
        captions: getAudioPath('/escoge-tu-clase.json', 'es-US')
      },
      chooseYourName: {
        src: getAudioPath('/escoge-tu-nombre.mp3', 'es-US'),
        captions: getAudioPath('/escoge-tu-nombre.json', 'es-US')
      },
      enterYourSecretCode: {
        src: getAudioPath('/ingresa-tu-codigo-secreto.mp3', 'es-US'),
        captions: getAudioPath('/ingresa-tu-codigo-secreto.json', 'es-US')
      },
      enterYourTeachersPin: {
        src: getAudioPath('/ingresa-tu-pin-de-maestro.mp3', 'es-US'),
        captions: getAudioPath('/ingresa-tu-pin-de-maestro.json', 'es-US')
      }
    },
    sfx: {
      sndWrong: {
        src: getAudioPath('/sfx/wrong.mp3')
      },
      sndCorrect: {
        src: getAudioPath('/sfx/correct.mp3')
      },
      sndMoving: {
        src: getAudioPath('/sfx/swoosh.mp3')
      }
    }
  },
  images: {
    letterCard1: {
      src: getImagePath('/letter-card-1.png')
    },
    letterCard2: {
      src: getImagePath('/letter-card-2.png')
    },
    letterCard3: {
      src: getImagePath('/letter-card-3.png')
    },
    letterCard4: {
      src: getImagePath('/letter-card-4.png')
    },
    letterCard5: {
      src: getImagePath('/letter-card-5.png')
    },
    letterCardA: {
      src: getImagePath('/letter-card-a.png')
    },
    letterCardB: {
      src: getImagePath('/letter-card-b.png')
    },
    letterCardC: {
      src: getImagePath('/letter-card-c.png')
    },
    letterCardD: {
      src: getImagePath('/letter-card-d.png')
    },
    letterCardE: {
      src: getImagePath('/letter-card-e.png')
    },
    matMan: {
      src: getImagePath('/mat-man.png')
    }
  }
};
