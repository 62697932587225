import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginComponent from 'components/modules/Login';
import PinRecovery from 'components/modules/Login/components/PinRecovery';

const AppRoutes = () => {
  const { app } = useSelector((state) => state.login);

  return (
    <Switch>
      <Route path="/login">
        <LoginComponent />
      </Route>
      <Route path="/pin-recovery">
        <PinRecovery />
      </Route>
      <Redirect to={{ pathname: '/login', search: `?app=${app}` }} />
    </Switch>
  );
};

AppRoutes.propTypes = {
  app: PropTypes.string,
  session: PropTypes.string,
  redirectURL: PropTypes.string
};

export default AppRoutes;
