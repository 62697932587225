import { AppName } from './types';

export default {
  app: {
    favicon: `${process.env.PUBLIC_URL}/favicons/${AppName.PRM_STUDENT}.ico`
  },
  header: {
    title: 'Phonics, Reading, and Me™',
    backgroundColor: '#655AA6',
    textColor: '#FFF',
    appNameTitle: `${AppName.PRM_STUDENT}`,
    displayAccessibilitySettings: false
  },
  body: {
    backgroundColor: '#FFF9C6'
  },
  buttons: {
    go: {
      backgroundColor: '#005BBB',
      textColor: '#FFF'
    }
  },
  links: {
    about: {
      href: 'https://www.lwtears.com/prm',
      textColor: '#0057A3'
    },
    support: {
      textColor: '#0057A3'
    }
  }
};
