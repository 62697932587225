import getAppConfig from 'app-config';
import React from 'react';
import { useSelector } from 'react-redux';

type Props = {
  className?: string;
};

const Background: React.FC<Props> = ({ className = '', children }) => {
  const config = useSelector((state) => getAppConfig(state.login.app));

  return (
    <div className={className} style={{ backgroundColor: config.body.backgroundColor }}>
      {children}
    </div>
  );
};

export default Background;
