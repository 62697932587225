import { get, post } from 'utils/api';
import { isEqual, uniqBy } from 'lodash';
import storage from 'store';
import { showSpinner, hideSpinner } from '../ui/actions';
import { setLocale } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

import {
  SET_CLASSROOMS,
  LOGIN_EDUCATOR_PIN,
  LOGIN_USER,
  GO_TO_STEP,
  SELECT_CLASS,
  SELECT_USER,
  SET_SESSION_ID,
  SET_APP,
  SET_REDIRECT,
  LOGIN_PIN_ERROR,
  LOGIN_USER_ERROR,
  LOGIN_STEP
} from 'state/login/types';

export const loginEducatorPin = (data) => ({ type: LOGIN_EDUCATOR_PIN, payload: data });
export const setSelectedClass = (classroom) => ({
  type: SELECT_CLASS,
  classroom
});
export const goToStep = (step) => ({ type: GO_TO_STEP, step });
export const selectedUser = (userId) => ({ type: SELECT_USER, selectedUserId: userId });
export const loginUserSuccess = () => ({ type: LOGIN_USER });
export const setSessionId = (sessionId) => ({ type: SET_SESSION_ID, sessionId });
export const setLoginApp = (app) => ({ type: SET_APP, app });
export const setRedirectURL = (url) => ({ type: SET_REDIRECT, url });

export const pinError = () => ({ type: LOGIN_PIN_ERROR });
export const loginError = (errorType) => ({ type: LOGIN_USER_ERROR, errorType });

export const selectClass = ({ id, name, locale }) => async (dispatch) => {
  dispatch(showSpinner());

  if (locale) {
    await setLocale(locale);
  }

  dispatch(setSelectedClass({ id, name, locale }));
  dispatch(hideSpinner());
};

export const setClassrooms = (classrooms, hasNewClassrooms) => ({
  type: SET_CLASSROOMS,
  classrooms,
  hasNewClassrooms,
  step: LOGIN_STEP.SELECT_CLASSES
});

export const setApp = (app) => async (dispatch) => {
  dispatch(setLoginApp(app));
};

export const setRedirect = (url) => async (dispatch) => {
  dispatch(setRedirectURL(url));
};

// used internally in App to redirect directly to Login classes
export const requestClassrooms = (pins) => async (dispatch) => {
  dispatch(showSpinner());

  let classrooms = [];
  const requests = pins.map((pin) => get('/classrooms', { params: { pin } }));
  const result = await Promise.all(requests);

  let validPins = pins; // Start with all pins; filter out invalid ones

  result.forEach(({ status, data, error }) => {
    if (status < 400 && data && !error) {
      classrooms = [...classrooms, ...data];
    } else if (status === 404) {
      const { response: { data: { pin = '' } = {} } = {} } = error;
      validPins = validPins.filter((i) => i !== pin); // If user was not found filter out this PIN
    }
  });

  storage.set('pins', validPins); // Set PINS only to those that were valid

  classrooms = sortClassrooms(classrooms);

  dispatch(setClassrooms(classrooms));
  dispatch(hideSpinner());

  // automatically select only class available
  if (classrooms.length === 1) selectClass(classrooms[0])(dispatch);

  return classrooms;
};

export const postSinglePin = (pin) => async (dispatch, getState) => {
  const { status, data, error } = await get('/classrooms', { params: { pin } });
  if (status < 400 && data && !error) {
    storePin(pin);
    const {
      login: { classrooms }
    } = getState();

    const mergedClassrooms = mergeClassrooms(classrooms, data);

    const hasNewClassrooms = !isEqual(
      classrooms.map(({ id }) => id).sort(),
      mergedClassrooms.map(({ id }) => id).sort()
    );

    dispatch(setClassrooms(mergedClassrooms, hasNewClassrooms));
    dispatch(loginEducatorPin(mergedClassrooms));

    // automatically select only class available
    if (mergedClassrooms.length === 1) dispatch(selectClass(mergedClassrooms[0]));
  } else {
    dispatch(pinError());
  }

  return status;
};

const handleRedirectUrl = (login, redirect) => {
  const loginUrl = new URL(login);
  const redirectUrl = new URL(redirect);

  for (const key of loginUrl.searchParams.keys()) redirectUrl.searchParams.delete(key);

  for (const [key, value] of loginUrl.searchParams.entries())
    redirectUrl.searchParams.append(key, value);

  return redirectUrl.toString();
};

export const loginUser = (studentId, password, classroomId) => async (dispatch, getState) => {
  dispatch(showSpinner());

  const {
    login: { app, redirectURL }
  } = getState();

  const options = { data: { classroomId, studentId, password } };
  const { data, status, error } = await post(`/login-app/${app}`, options);
  if (status < 400 && data && !error) {
    const { sessionId, loginUrl } = data;
    dispatch(setSessionId(sessionId));

    if (['dev-next', 'qa01'].includes(process.env.REACT_APP_BUILD_ENV) && redirectURL?.length)
      window.location.replace(handleRedirectUrl(loginUrl, redirectURL));
    else window.location.replace(loginUrl);

    dispatch(loginUserSuccess());
  } else {
    dispatch(hideSpinner());
    dispatch(loginError(error?.response?.data?.code));
  }
};

export const selectUser = (userId) => async (dispatch, getState) => {
  const {
    login: { users }
  } = getState();

  dispatch(showSpinner());
  const currentUser = users.find((el) => el.id === userId);

  if (currentUser && currentUser.locale) {
    await setLocale(currentUser.locale);
  }

  dispatch(selectedUser(userId));
  dispatch(hideSpinner());
};

const storePin = (pin) => {
  const validPins = storage.get('pins') || [];
  const newPins = [...new Set([...validPins, pin])];
  storage.set('pins', newPins);
};

export const mergeClassrooms = (original = [], newClassrooms = []) =>
  sortClassrooms(uniqBy([...original, ...newClassrooms], 'id'));

const sortClassrooms = (classrooms) =>
  classrooms.sort((a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();
    return aName.localeCompare(bName);
  });
