import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { LOGIN_STEP } from 'state/login/types';
import common from '../style/common.module.scss';
import getAppConfig from 'app-config';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';
import { A11ySettingsPopover } from '@lwtears/lwt-common-frontend/lib/@a11y/A11ySettings';
import arrowBackButton from 'assets/svg/arrow-back-button.svg';
import { applyA11ySettings } from '@lwtears/lwt-common-frontend/lib/@common/util/a11y-util';
import { getAudioVendor } from 'utils/accessibilityUtils';
import { useLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';

const t = (key) => {
  return translate('common', key);
};

const Header = ({ goToStep, previousStep, message, logoOnly }) => {
  const config = useSelector((state) => getAppConfig(state.login.app));
  const [
    {
      settings: {
        accessibility: {
          displaySettings: { contentScaling }
        }
      }
    }
  ] = useLwtStore();

  return (
    <div
      className={[common.header, common[`header${contentScaling}`]].join(' ')}
      style={{ backgroundColor: config.header.backgroundColor }}
    >
      <div
        className={[common.headerContent, logoOnly ? common.onlyLogoContentHeader : ''].join(' ')}
      >
        {!logoOnly && (
          <div className={common.goBack}>
            <button
              onClick={() => goToStep(previousStep)}
              className={`${
                !config.header.arrowBackImage ? common.buttonSvg : common.buttonSmallSvg
              } login-button go-back`}
              src={!config.header.arrowBackImage ? arrowBackButton : config.header.arrowBackImage}
              aria-label={t('GoBack')}
              id={`backButton`}
            >
              <img
                src={!config.header.arrowBackImage ? arrowBackButton : config.header.arrowBackImage}
                className={[common.img, common[`img${contentScaling}`]].join(' ')}
                alt={t('GoBackArrow')}
              />
            </button>
            <div style={{ color: config.header.textColor }}>
              <p
                className={[
                  common.previousStepText,
                  common[`previousStepText${contentScaling}`]
                ].join(' ')}
              >
                {previousStep + 1 === LOGIN_STEP.SHOW_PIN
                  ? t('Not') + `${message}?`
                  : t('DontSeeYour') + `${message}?`}
              </p>
              <p
                className={[
                  common.previousStepText,
                  common[`previousStepText${contentScaling}`]
                ].join(' ')}
              >
                {t('GoBack')}
              </p>
            </div>
          </div>
        )}
        <div className={common.headerContainer}>
          {config.header.title && (
            <div className={common.title} style={{ color: config.header.textColor }}>
              {config.header.title}
            </div>
          )}
          {config.header.image && (
            <img
              className={common.headerImg}
              src={config.header.image}
              alt={`${config.header.appNameTitle ?? ''} ${t('logo')}`}
            />
          )}
        </div>
        {config.header.displayAccessibilitySettings && (
          <div style={{ position: 'relative', right: 20, top: 20 }}>
            <A11ySettingsPopover
              onSubmit={(response) => applyA11ySettings(response.data, getAudioVendor())}
            />
          </div>
        )}
      </div>
      {config.header.borderImage && (
        <img className={common.borderImage} src={config.header.borderImage} alt="" />
      )}
      {!config.header.borderImage && <div className={common.border} />}
    </div>
  );
};

Header.propTypes = {
  goToStep: PropTypes.func,
  previousStep: PropTypes.number,
  logoOnly: PropTypes.bool,
  message: PropTypes.string
};

export default Header;
