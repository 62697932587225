import React, { useEffect, useState } from 'react';
import storage from 'store';
import { initAnalytics } from 'utils/init-analytics';
import { setApp, setRedirect } from 'state/login/actions';
import { useDispatch, useSelector } from 'react-redux';
import loginStyles from '../Login/style/Login.module.scss';
import 'styles/global.scss';
import getAppConfig, { FALLBACK_APP } from 'app-config';
import {
  useAppDescription,
  useAppFavicon,
  useAppTitle
} from '@lwtears/lwt-common-frontend/lib/@common';
import { translate } from '@lwtears/lwt-common-frontend/lib/@common/util/i18n-util';

const tApp = (app: string, key: string) => translate(app, key);

import { applyA11ySettings } from '@lwtears/lwt-common-frontend/lib/@common/util/a11y-util';
import { getAudioVendor } from 'utils/accessibilityUtils';
import { useLwtStore } from '@lwtears/lwt-common-frontend/lib/LwtApp';

const getLastApp = () => {
  const app = storage.get('lastApp');
  const isValidApp = !!getAppConfig(app);

  return isValidApp ? app : FALLBACK_APP;
};

const getApp = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const app = searchParams.get('app') || getLastApp();

  return {
    app,
    redirectUrl: searchParams.has('redirectUrl') ? searchParams.get('redirectUrl') : null
  };
};

export const addEventListener = (event: string, callback: any) =>
  document.addEventListener(event, callback);

export const removeEventListener = (event: string, callback: any) =>
  document.removeEventListener(event, callback);

const onMouseDownCallback = () => {
  const classList = document.body.classList;
  if (classList.contains('using-keyboard')) classList.remove('using-keyboard');
};

const onKeyDownCallback = (event: any) => {
  const classList = document.body.classList;
  if (event.key === 'Tab' && !classList.contains('using-keyboard')) {
    classList.add('using-keyboard');
  }
};

export const initEventListeners = () => {
  addEventListener('mousedown', onMouseDownCallback);
  addEventListener('keydown', onKeyDownCallback);
};

export const removeEventListeners = () => {
  removeEventListener('mousedown', onMouseDownCallback);
  removeEventListener('keydown', onKeyDownCallback);
};

const AppInit: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const app = useSelector((state) => state.login.app);
  const [{ settings }] = useLwtStore();
  const config = getAppConfig(app);

  useAppFavicon(config?.app?.favicon);
  useAppTitle(tApp(app, 'appTitle'));
  useAppDescription(tApp(app, 'appDescription'));

  // run once: init app
  useEffect(() => {
    const initApp = async () => {
      const { app, redirectUrl } = getApp();

      await dispatch(setApp(app));
      storage.set('lastApp', app);
      applyA11ySettings(settings, getAudioVendor());

      if (redirectUrl) {
        await dispatch(setRedirect(redirectUrl));
      }

      setLoading(false);
    };

    initApp();
  }, []);

  // run once: init app listeners
  useEffect(() => {
    initEventListeners();

    return () => removeEventListeners();
  }, []);

  // run once: init analytics
  useEffect(() => {
    initAnalytics();
  }, []);

  if (loading) {
    return (
      <div className={loginStyles.loginComponent}>
        <div className={[loginStyles.loading, 'loader'].join(' ')}></div>
      </div>
    );
  }
  return <>{children}</>;
};

export default AppInit;
