import Gss from './gss';
import Keyboarding from './keyboarding';
import HwtStudent from './hwt-student';
import Wdt from './wdt';
import MatManStudent from './mat-man-student';
import PRMStudent from './prm-student';

export const FALLBACK_APP = 'hwt-student-25';

const apps = {
  wdt: Wdt,
  gss: Gss,
  keyboarding: Keyboarding,
  'hwt-student-22': HwtStudent,
  'hwt-student-25': HwtStudent,
  'mat-man-student': MatManStudent,
  'prm-student': PRMStudent
};

export const getAppConfig = (app) => {
  return apps[app];
};

export default getAppConfig;
