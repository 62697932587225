import React from 'react';
import Routes from 'components/routes/AppRoutes';

const App: React.FC = () => (
  <div id="mainContainer" role="main">
    <Routes />
  </div>
);

export default App;
