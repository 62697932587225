function gtag(...args) {
  (window as any).dataLayer.push(...args);
}

export const initAnalytics = () => {
  (window as any).dataLayer = (window as any).dataLayer || [];

  gtag('js', new Date());
  gtag('config', process.env.GOOGLE_KEY);
};
