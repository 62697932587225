import store from 'state';
import { setCurrentCaption } from 'state/ui/actions';

let DISPATCH_INTERVAL = null;

const syncAudioCaption = (audio, cue) => {
  const getTimeStamp = () => {
    if (audio.playing()) {
      const timeStamp = audio.seek();
      displayCaption(timeStamp, cue);
      setTimeout(getTimeStamp, 100);
    }
  };

  getTimeStamp();
};

const initListeners = (audio, cue) => {
  const { captionsEnabled = true } = store.getState();

  if (!captionsEnabled || !cue) return;

  audio.on('play', () => syncAudioCaption(audio, cue));
};

const displayCaption = (audioTimeStamp, cue) => {
  const timeStamp = audioTimeStamp.toFixed(1);
  const found = formatTimeStamp(cue.start) === timeStamp;

  if (found) {
    const { start, end, payload } = cue;
    store.dispatch(setCurrentCaption(payload));

    clearInterval(DISPATCH_INTERVAL);

    DISPATCH_INTERVAL = setTimeout(() => {
      store.dispatch(setCurrentCaption(''));
    }, (formatTimeStamp(end) - formatTimeStamp(start)) * 1500);
  }
};

const formatTimeStamp = (time) => {
  const [h, m, s] = time.split(':');
  return parseFloat(h * 3600 + m * 60 + s * 1).toFixed(1);
};

const HowlWithCCs = (howl, { cues }) => {
  const [cue] = cues;
  initListeners(howl, cue);
  return howl;
};

export default HowlWithCCs;
