export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_MOBILE = 'SET_MOBILE';
export const SET_LOGIN_SOUNDS = 'SET_LOGIN_SOUNDS';
export const SET_COMMON_SOUNDS = 'SET_COMMON_SOUNDS';
export const SHOW_FORWARD_BUTTON = 'SHOW_FORWARD_BUTTON';
export const HIDE_FORWARD_BUTTON = 'HIDE_FORWARD_BUTTON';
export const SET_PULSE_TIMESTAMP = 'SET_PULSE_TIMESTAMP';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const CLEAR_LOGIN_SOUNDS = 'CLEAR_LOGIN_SOUNDS';
export const SET_CURRENT_CC = 'SET_CURRENT_CC';
