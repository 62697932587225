import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import LwtApp from '@lwtears/lwt-common-frontend/lib/LwtApp';
import LoginApp from 'components/modules/App/App';
import AppInit from 'components/modules/App/AppInit';
import store from './state';
import { AssetCacheProvider } from 'state/providers/AssetCache';

import commonEn from 'translations/en-US/common.json';
import commonEs from 'translations/es-US/common.json';
import gssEn from 'translations/en-US/gss.json';
import gssEs from 'translations/es-US/gss.json';
import hwtStudentEn from 'translations/en-US/hwt-student.json';
import hwtStudentEs from 'translations/es-US/hwt-student.json';
import keyboardingEn from 'translations/en-US/keyboarding.json';
import keyboardingEs from 'translations/es-US/keyboarding.json';
import wdtEn from 'translations/en-US/wdt.json';
import wdtEs from 'translations/es-US/wdt.json';
import matManStudentEn from 'translations/en-US/mat-man-student.json';
import matManStudentEs from 'translations/es-US/mat-man-student.json';
import prmStudentEn from 'translations/en-US/prm-student.json';
import prmStudentEs from 'translations/es-US/prm-student.json';

import './styles/global.scss';

const i18n = {
  resourceMap: {
    'en-US': {
      common: commonEn,
      gss: gssEn,
      'hwt-student-22': hwtStudentEn,
      'hwt-student-25': hwtStudentEn,
      keyboarding: keyboardingEn,
      wdt: wdtEn,
      'mat-man-student': matManStudentEn,
      'prm-student': prmStudentEn
    },
    'es-US': {
      common: commonEs,
      gss: gssEs,
      'hwt-student-22': hwtStudentEs,
      'hwt-student-25': hwtStudentEs,
      keyboarding: keyboardingEs,
      wdt: wdtEs,
      'mat-man-student': matManStudentEs,
      'prm-student': prmStudentEs
    }
  }
};

const App = () => (
  <LwtApp i18n={i18n}>
    <ReduxProvider store={store}>
      <AssetCacheProvider>
        <Router>
          <AppInit>
            <LoginApp />
          </AppInit>
        </Router>
      </AssetCacheProvider>
    </ReduxProvider>
  </LwtApp>
);

ReactDOM.render(<App />, document.getElementById('root'));
