import { Howler } from 'howler';

const getAudioVendor = () => Howler;

const handleSettingChangeSubscription = (setting, newValue) => {
  switch (setting) {
    case 'muteSounds': {
      Howler.mute(newValue);
      break;
    }
    case 'volume': {
      Howler.volume(newValue / 100);
      break;
    }
  }
};

export { getAudioVendor, handleSettingChangeSubscription };
